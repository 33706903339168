define('due-dashboard/components/chat-status-filter', ['exports', 'ember', 'due-dashboard/helpers/thousand-separator'], function (exports, _ember, _dueDashboardHelpersThousandSeparator) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;

  /**
   * @author Marco REGUILLOT <marco.reguillot@diduenjoy.com>
   * @summary {{due-select}} select component
   *
   * @module
   * @argument {string} ctlStatus - ctl status value
   * @argument {string} status - status value
   * @argument {object} chatRepartition - chat repartition object (see models/chat-repartition.js)
   * @argument {function} onChange - Action handler on status or ctlStatus changed
   */
  exports['default'] = Component.extend({
    chatRepartition: null,
    onChange: null,

    ctlStatus: 'all',
    status: 'all',
    userChoseStatus: false,

    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    overdueAndOnTimeOpened: computed('status', 'ctlStatus', function () {
      return ['unresolved', 'on_time', 'overdue'].includes(this.get('status')) && this.get('ctlStatus') === 'ctl_enabled';
    }),

    counters: computed('chatRepartition', 'ctlStatus', function () {
      var chatRepartition = this.get('chatRepartition');
      var ctlStatus = this.get('ctlStatus');
      if (!chatRepartition) {
        return {
          all_count: '-',
          unresolved_count: '-',
          resolved_count: '-',
          overdue_count: '-',
          on_time_count: '-'
        };
      }

      var overdueCount = chatRepartition.get(ctlStatus).overdue_count;
      if (this.get('userChoseStatus') === false && ctlStatus === 'ctl_enabled' && this.get('status') === 'overdue' && overdueCount === 0) {
        this.set('status', 'unresolved');
        var onChangeEvent = this.get('onChange');
        if (onChangeEvent) {
          onChangeEvent(ctlStatus, 'unresolved');
        }
      }

      return {
        all_count: _dueDashboardHelpersThousandSeparator['default'].compute(chatRepartition.get(ctlStatus).all_count, this.get('currentLanguage')),
        unresolved_count: _dueDashboardHelpersThousandSeparator['default'].compute(chatRepartition.get(ctlStatus).unresolved_count, this.get('currentLanguage')),
        resolved_count: _dueDashboardHelpersThousandSeparator['default'].compute(chatRepartition.get(ctlStatus).resolved_count, this.get('currentLanguage')),
        overdue_count: _dueDashboardHelpersThousandSeparator['default'].compute(overdueCount || 0, this.get('currentLanguage')),
        on_time_count: _dueDashboardHelpersThousandSeparator['default'].compute(chatRepartition.get(ctlStatus).on_time_count || 0, this.get('currentLanguage'))
      };
    }),

    actions: {
      changeCtlStatus: function changeCtlStatus(ctlStatus) {
        this.set('chatRepartition', null);
        this.set('ctlStatus', ctlStatus);
        var status = this.get('status');
        if (ctlStatus === 'all' && ['on_time', 'overdue'].includes(status)) {
          status = 'unresolved';
          this.set('status', status);
        } else if (ctlStatus === 'ctl_enabled') {
          this.set('userChoseStatus', false);
          status = 'overdue';
          this.set('status', status);
        }

        var onChangeEvent = this.get('onChange');
        if (onChangeEvent) {
          onChangeEvent(ctlStatus, status);
        }
      },

      changeStatus: function changeStatus(status) {
        this.set('userChoseStatus', true);
        this.set('status', status);
        var onChangeEvent = this.get('onChange');
        if (onChangeEvent) {
          onChangeEvent(this.get('ctlStatus'), status);
        }
      }
    }
  });
});