define('due-dashboard/controllers/companies/report', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Controller.extend({
    globalFilters: service('feedback-search/global'),

    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    canUseSummaries: computed.alias('currentAccount.canUseSummaries'),

    reload: false,
    TIME_SELECTOR: computed(function () {
      return [{ key: 'day', label: this.get('i18n').t('words.day') }, { key: 'week', label: this.get('i18n').t('words.week') }, { key: 'month', label: this.get('i18n').t('words.month') }, { key: 'year', label: this.get('i18n').t('words.year') }];
    }),

    canView: computed('model.id', function () {
      var hasReportCustomRight = this.get('currentAccount.hasReportCustomRight'),
          reportRightsList = this.get('currentAccount.reportRightsList');

      return !hasReportCustomRight || reportRightsList.includes(this.get('report.id'));
    }),

    geoscopeName: computed('currentAccount.content', function () {
      if (this.get('currentAccount.content.profile') === 'admin' && !this.get('currentAccount.content.geoscope_name.length')) {
        return 'Worldwide';
      }
      return this.get('currentAccount.content.geoscope_name.length') > 0 ? this.get('currentAccount.content.geoscope_name') : '-';
    }),

    roleName: computed('currentAccount.content.account_custom_profile_right', function () {
      if (this.get('currentAccount.content.account_custom_profile_right.content')) {
        return this.get('currentAccount.content.account_custom_profile_right.name');
      } else {
        var profile = this.get('currentAccount.content.profile');
        return profile.charAt(0).toUpperCase() + profile.slice(1);
      }
    }),

    defaultValueIndex: computed('globalViewByType', function () {
      return this.get('globalViewByType') == 'time_selector' ? 1 : 0;
    }),

    globalViewBy: computed('model.report.metafield', function () {
      var elementList = this.get('elementList');
      if (!elementList) {
        return {};
      }
      return {
        selectedElement: elementList[this.get('defaultValueIndex')],
        elementList: elementList
      };
    }),

    elementList: computed('globalViewByType', function () {
      var globalViewByType = this.get('globalViewByType');
      if (!globalViewByType) {
        return;
      }
      if (globalViewByType != 'time_selector') {
        return this.get('globalViewByValues');
      }
      return this.get('TIME_SELECTOR');
    }),

    globalViewByType: computed('model.report.metafield', function () {
      return this.get('model.report.metafield.view_by.type');
    }),

    globalViewByValues: computed('model.report.metafield', function () {
      return this.get('model.report.metafield.view_by.values');
    }),

    enableGlobalViewBy: computed('globalViewByType', function () {
      return !!this.get('globalViewByType');
    }),

    report: computed.alias('model'),

    title: computed('model', function () {
      var title = this.get('model.report.title') || {};
      return title[this.get('currentLanguage')] || title[this.get('currentAccount.content.company.language.tag')];;
    }),

    hidePeriodComparison: computed('model.report', function () {
      var path = window.location.pathname.split('/');
      var disabledPages = ['touchpoint_analysis_std_v2', 'segment_analysis_std_v2', 'kpi_analysis_std_v2', 'tag_analysis_std_v2'];

      return path.length > 3 && disabledPages.includes(path[3]);
    }),

    reportGoBackTo: computed('model.report', function () {
      var path = window.location.pathname.split('/');
      var graphAnalysisPage = ['touchpoint_analysis_std_v2', 'segment_analysis_std_v2', 'kpi_analysis_std_v2', 'tag_analysis_std_v2'];

      if (path.length > 3 && graphAnalysisPage.includes(path[3])) {
        return 'companies.graphs-analysis';
      }
    }),

    displayBackButton: computed('model.report', function () {
      var path = window.location.pathname.split('/');
      var enabledPages = ['touchpoint_analysis_std_v2', 'segment_analysis_std_v2', 'kpi_analysis_std_v2', 'tag_analysis_std_v2'];

      return path.length > 3 && enabledPages.includes(path[3]);
    }),

    actions: {
      toggleReload: function toggleReload() {
        this.toggleProperty('reload');
        this.set('displaySummary', false); // Temp Summaries POC
      },

      changeReportRoute: function changeReportRoute(route, params) {
        if (params) {
          this.transitionToRoute(route, params);
        } else {
          this.transitionToRoute(route);
        }
      },

      redirectToDashboard: function redirectToDashboard() {
        var sidebar = this.get('currentAccount.content.account_custom_profile_right.sidebar'),
            element = sidebar.get('elements').find(function (e) {
          if (e.type != 'title') {
            return e;
          }
        }),
            route = element.route,
            param = element.param;

        this.transitionToRoute(route, param);
      },

      // Summaries POC
      generateSummary: function generateSummary(tag_id) {
        var _this = this;

        var summaryFilters = this.get('globalFilters.current').toAPIFormat();

        this.set('displaySummary', true);
        this.set('summaryPromise', this.get('store').createRecord('summary', {
          filters: summaryFilters,
          tag: this.get('store').peekRecord('tag', tag_id)
        }).save()).then(function (summary) {
          _this.set('summary', summary);
        })['catch'](function (error) {
          _this.set('SummaryError', error);
          setTimeout(function () {
            return _this.set('displaySummary', false);
          }, 5000);
        });
      },

      onSelectSegment: function onSelectSegment(value) {
        this.get('globalViewBy.selectedElement', value);
      },

      goBackTo: function goBackTo(route) {
        this.transitionToRoute(route);
      }
    }
  });
});