define("due-dashboard/templates/companies/report", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 8,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/companies/report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "due-btn", [], ["icon", "tio-chevron_left", "intent", "secondary", "size", "small", "action", ["subexpr", "action", ["goBackTo", ["get", "reportGoBackTo", ["loc", [null, [7, 102], [7, 116]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 83], [7, 117]]], 0, 0]], ["loc", [null, [7, 10], [7, 119]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 12
                },
                "end": {
                  "line": 38,
                  "column": 12
                }
              },
              "moduleName": "due-dashboard/templates/companies/report.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "dropdown-text");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "segment.label", ["loc", [null, [37, 42], [37, 59]]], 0, 0, 0, 0]],
            locals: ["segment"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 6
              },
              "end": {
                "line": 41,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/companies/report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "global-view-by-section");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "segment-selector selector");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "due-select", [], ["value", ["subexpr", "@mut", [["get", "globalViewBy.selectedElement", ["loc", [null, [27, 20], [27, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "globalViewBy.elementList", ["loc", [null, [28, 22], [28, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "componentTextKey", "components.reports.array.view_by", "selectedComponentName", "due-table/elements/-selected-item", "onChange", ["subexpr", "action", ["onSelectSegment"], [], ["loc", [null, [31, 23], [31, 49]]], 0, 0], "containerSelector", ".report-chart", "displayAttribute", "label", "rightAlign", true], 0, null, ["loc", [null, [26, 12], [38, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 10
                  },
                  "end": {
                    "line": 60,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/companies/report.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "component", ["reports/due-report-table/due-report-table-dynamic"], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [50, 22], [50, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [51, 39], [51, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 21], [51, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [52, 30], [52, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [53, 28], [53, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "report", ["loc", [null, [54, 21], [54, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [55, 21], [55, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [56, 27], [56, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [57, 29], [57, 57]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [58, 22], [58, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [49, 12], [59, 14]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 60,
                      "column": 10
                    },
                    "end": {
                      "line": 74,
                      "column": 10
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/report.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "component", ["reports/due-report-table/due-report-table"], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [62, 22], [62, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [63, 39], [63, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 21], [63, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [64, 30], [64, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [65, 28], [65, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "report", ["loc", [null, [66, 21], [66, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [67, 21], [67, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [68, 27], [68, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [69, 29], [69, 57]]], 0, 0], "generateSummaryAction", ["subexpr", "action", ["generateSummary"], [], ["loc", [null, [70, 36], [70, 62]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [71, 22], [71, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "canUseSummaries", ["subexpr", "@mut", [["get", "canUseSummaries", ["loc", [null, [72, 30], [72, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [61, 12], [73, 14]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 74,
                        "column": 10
                      },
                      "end": {
                        "line": 89,
                        "column": 10
                      }
                    },
                    "moduleName": "due-dashboard/templates/companies/report.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "component", ["reports/due-report-table/due-report-table"], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [76, 22], [76, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [77, 39], [77, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 21], [77, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [78, 30], [78, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [79, 28], [79, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "report", ["loc", [null, [80, 21], [80, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [81, 21], [81, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [82, 27], [82, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [83, 29], [83, 57]]], 0, 0], "generateSummaryAction", ["subexpr", "action", ["generateSummary"], [], ["loc", [null, [84, 36], [84, 62]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [85, 22], [85, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "canUseSummaries", ["subexpr", "@mut", [["get", "canUseSummaries", ["loc", [null, [86, 30], [86, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "dataType", "group"], ["loc", [null, [75, 12], [88, 14]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 89,
                          "column": 10
                        },
                        "end": {
                          "line": 99,
                          "column": 10
                        }
                      },
                      "moduleName": "due-dashboard/templates/companies/report.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "component", [["subexpr", "concat", ["reports/-", ["get", "column.component.type", ["loc", [null, [90, 44], [90, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [90, 24], [90, 66]]], 0, 0]], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [91, 22], [91, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [92, 39], [92, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [92, 21], [92, 53]]], 0, 0], "summary", ["subexpr", "@mut", [["get", "summary", ["loc", [null, [93, 22], [93, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "canUseSummaries", ["subexpr", "@mut", [["get", "canUseSummaries", ["loc", [null, [94, 30], [94, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "SummaryError", ["subexpr", "@mut", [["get", "SummaryError", ["loc", [null, [95, 27], [95, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "displaySummary", ["subexpr", "@mut", [["get", "displaySummary", ["loc", [null, [96, 29], [96, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "summaryPromise", ["subexpr", "@mut", [["get", "summaryPromise", ["loc", [null, [97, 29], [97, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [90, 12], [98, 14]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 99,
                          "column": 10
                        },
                        "end": {
                          "line": 114,
                          "column": 10
                        }
                      },
                      "moduleName": "due-dashboard/templates/companies/report.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n          ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "component", [["subexpr", "concat", ["reports/-", ["get", "column.component.type", ["loc", [null, [100, 44], [100, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [100, 24], [100, 66]]], 0, 0]], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [101, 22], [101, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [102, 39], [102, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [102, 21], [102, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [103, 30], [103, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [104, 28], [104, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "model.report", ["loc", [null, [105, 21], [105, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [106, 21], [106, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [107, 27], [107, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [108, 29], [108, 57]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [109, 22], [109, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [110, 20], [110, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "pieSize", ["subexpr", "@mut", [["get", "column.component.config.pie_size", ["loc", [null, [111, 22], [111, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "globalViewBy", ["subexpr", "@mut", [["get", "globalViewBy.selectedElement", ["loc", [null, [112, 27], [112, 55]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [100, 12], [113, 14]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 89,
                        "column": 10
                      },
                      "end": {
                        "line": 114,
                        "column": 10
                      }
                    },
                    "moduleName": "due-dashboard/templates/companies/report.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "eq", [["get", "column.component.type", ["loc", [null, [89, 24], [89, 45]]], 0, 0, 0, 0], "summary"], [], ["loc", [null, [89, 20], [89, 56]]], 0, 0]], [], 0, 1, ["loc", [null, [89, 10], [114, 10]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 74,
                      "column": 10
                    },
                    "end": {
                      "line": 114,
                      "column": 10
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/report.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "column.component.view_type", ["loc", [null, [74, 24], [74, 50]]], 0, 0, 0, 0], "group"], [], ["loc", [null, [74, 20], [74, 59]]], 0, 0]], [], 0, 1, ["loc", [null, [74, 10], [114, 10]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 10
                  },
                  "end": {
                    "line": 114,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/companies/report.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["subexpr", "or", [["subexpr", "eq", [["get", "column.component.type", ["loc", [null, [60, 33], [60, 54]]], 0, 0, 0, 0], "array"], [], ["loc", [null, [60, 29], [60, 63]]], 0, 0], ["subexpr", "eq", [["get", "column.component.type", ["loc", [null, [60, 68], [60, 89]]], 0, 0, 0, 0], "tag-breakdown"], [], ["loc", [null, [60, 64], [60, 106]]], 0, 0]], [], ["loc", [null, [60, 25], [60, 107]]], 0, 0], ["subexpr", "and", [["get", "column.component.api_version", ["loc", [null, [60, 113], [60, 141]]], 0, 0, 0, 0], ["subexpr", "eq", [["get", "column.component.api_version", ["loc", [null, [60, 146], [60, 174]]], 0, 0, 0, 0], 2], [], ["loc", [null, [60, 142], [60, 177]]], 0, 0]], [], ["loc", [null, [60, 108], [60, 178]]], 0, 0]], [], ["loc", [null, [60, 20], [60, 179]]], 0, 0]], [], 0, 1, ["loc", [null, [60, 10], [114, 10]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 8
                },
                "end": {
                  "line": 115,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/companies/report.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "column.component.view_type", ["loc", [null, [48, 20], [48, 46]]], 0, 0, 0, 0], "segment-breakdown"], [], ["loc", [null, [48, 16], [48, 67]]], 0, 0]], [], 0, 1, ["loc", [null, [48, 10], [114, 17]]]]],
            locals: ["column"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 4
              },
              "end": {
                "line": 117,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/companies/report.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "report-line-container");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "line", ["loc", [null, [47, 16], [47, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [47, 8], [115, 17]]]]],
          locals: ["line"],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 2
              },
              "end": {
                "line": 121,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/companies/report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "reports/popups/access-denied", [], ["isOpen", ["subexpr", "not", [["get", "canView", ["loc", [null, [120, 47], [120, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [120, 42], [120, 55]]], 0, 0], "action", ["subexpr", "action", ["redirectToDashboard"], [], ["loc", [null, [120, 63], [120, 93]]], 0, 0]], ["loc", [null, [120, 4], [120, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 122,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/companies/report.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "report-page-header");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "header-section");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "header-title-line");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "header-title");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "user-role-access-container");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "icon-container");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5, "class", "tio-poi");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "text-container");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "group-name");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "filters-container");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "report-container");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3, 3]);
          var element4 = dom.childAt(element3, [3]);
          var element5 = dom.childAt(element0, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[3] = dom.createAttrMorph(element4, 'class');
          morphs[4] = dom.createMorphAt(element4, 0, 0);
          morphs[5] = dom.createMorphAt(element5, 1, 1);
          morphs[6] = dom.createMorphAt(element5, 3, 3);
          morphs[7] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[8] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "displayBackButton", ["loc", [null, [6, 14], [6, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 8], [8, 15]]]], ["content", "title", ["loc", [null, [9, 35], [9, 44]]], 0, 0, 0, 0], ["content", "roleName", ["loc", [null, [16, 35], [16, 47]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["location-name ", ["subexpr", "if", [["subexpr", "gt", [["get", "currentAccount.content.geoscope_name.length", ["loc", [null, [17, 46], [17, 89]]], 0, 0, 0, 0], 50], [], ["loc", [null, [17, 42], [17, 93]]], 0, 0], "overflow-ellipsis"], [], ["loc", [null, [17, 37], [17, 115]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "geoscopeName", ["loc", [null, [17, 117], [17, 133]]], 0, 0, 0, 0], ["inline", "dashboard-filters", [], ["filtersUpdated", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [22, 41], [22, 64]]], 0, 0], "hidePeriodComparison", ["subexpr", "@mut", [["get", "hidePeriodComparison", ["loc", [null, [22, 86], [22, 106]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [22, 6], [22, 108]]], 0, 0], ["block", "if", [["get", "enableGlobalViewBy", ["loc", [null, [23, 12], [23, 30]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [23, 6], [41, 13]]]], ["block", "each", [["get", "model.report.elements", ["loc", [null, [45, 12], [45, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [45, 4], [117, 13]]]], ["block", "unless", [["get", "canView", ["loc", [null, [119, 12], [119, 19]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [119, 2], [121, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 123,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/companies/report.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "model.isPending", ["loc", [null, [2, 10], [2, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [122, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});