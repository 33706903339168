define('due-dashboard/components/due-table/elements/-selected-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-selected-item'],

    text: computed('value', function () {
      var textKey = this.get('textKey'),
          text = this.get('i18n').t(textKey, { value: this.get('value.' + (this.get('displayAttribute') || 'text')) || this.get('value') });

      return text;
    })
  });
});