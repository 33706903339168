define("due-dashboard/templates/components/reports/-chart", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-chart.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "reports/charts/-combo-bar-line", [], ["config", ["subexpr", "@mut", [["get", "serializedChartConfiguration", ["loc", [null, [3, 11], [3, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [4, 12], [4, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "reportFilters", ["loc", [null, [5, 18], [5, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [6, 11], [6, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "globalViewBy", ["subexpr", "@mut", [["get", "globalViewBy", ["loc", [null, [7, 17], [7, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [8, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 0
              },
              "end": {
                "line": 17,
                "column": 0
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-chart.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "reports/charts/-scatter-plot", [], ["config", ["subexpr", "@mut", [["get", "serializedChartConfiguration", ["loc", [null, [11, 11], [11, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [12, 12], [12, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "reportFilters", ["loc", [null, [13, 18], [13, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [14, 11], [14, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "globalViewBy", ["subexpr", "@mut", [["get", "globalViewBy", ["loc", [null, [15, 17], [15, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 2], [16, 4]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-chart.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "content.component.view_type", ["loc", [null, [9, 14], [9, 41]]], 0, 0, 0, 0], "scatter"], [], ["loc", [null, [9, 10], [9, 52]]], 0, 0]], [], 0, null, ["loc", [null, [9, 0], [17, 0]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/reports/-chart.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "eq", [["get", "content.component.view_type", ["loc", [null, [1, 10], [1, 37]]], 0, 0, 0, 0], "comboBarLine"], [], ["loc", [null, [1, 6], [1, 53]]], 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [17, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});