define('due-dashboard/models/chart', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    content_data: attr(),
    kpis: attr(),
    filters: attr(),
    groups: attr(),
    date_range: attr(),
    orders: attr(),
    tz: attr('string')
  });
});