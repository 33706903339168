define('due-dashboard/components/dashboard-filters/segments', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({

    filters: service('filters'),

    segment: computed('filter.value.id', function () {
      var id = this.get('filter.value.id');

      return this.get('store').peekOrFind('segment', id);
    }),

    allValues: _ember['default'].RSVP.resolve([]),

    availableValues: computed('allValues', 'filter.value.values', 'inputValue', function () {
      return this.get('inputValue').includes(this.INPUT_PARSING_CHARACTER) ? [this.get('DEFAULT_PARSING_MODE_OPTION')] : _.difference(this.get('allValues'), this.get('filter.value.values'));
    }),

    inputValue: '',
    parseFilterInput: false,
    INPUT_PARSING_CHARACTER: ';',
    invalidFilterValues: [],
    operatorOptions: [{ value: 'eq', translation: 'sidebar.filters.segments.operator.eq' }, { value: 'neq', translation: 'sidebar.filters.segments.operator.neq' }, { value: 'like', translation: 'sidebar.filters.segments.operator.like' }, { value: 'ulike', translation: 'sidebar.filters.segments.operator.ulike' }],

    selectedOperator: computed('operatorOptions', 'filter.value.operator', function () {
      var operator = this.get('filter.value.operator');
      if (operator) return this.get('operatorOptions').filter(function (s) {
        return s.value == operator;
      })[0];
      return this.get('operatorOptions.firstObject');
    }),

    containsInput: computed('filter.value.{operator,values}', function () {
      return this.get('filter.value.operator') === 'like' || this.get('filter.value.operator') === 'ulike' ? this.get('filter.value.values.firstObject') : '';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._searchValues('');
    },

    DEFAULT_PARSING_MODE_OPTION: computed(function () {
      return this.get('i18n').t('sidebar.filters.segments.parsing_dropdown_default_option').string;
    }),

    lineLabel: computed('segment.name', function () {
      return this.get('i18n').t('words.segment') + ' : ' + this.get('segment.name');
    }),

    dueInputProps: computed('segment.name', function () {
      return {
        'class': 'filter-label',
        // label: this.get('segment.name'),
        placeholder: this.get('i18n').t('sidebar.filters.segments.input_placeholder')
      };
    }),

    disableDropdownEnterKey: computed('inputValue', function () {
      return this.get('inputValue').includes(this.INPUT_PARSING_CHARACTER);
    }),

    invalidFilterValuesMsg: computed('invalidFilterValues', function () {
      var list = this.get('invalidFilterValues').join(', ');
      return this.get('i18n').t('sidebar.filters.segments.invalid_value_error', { values: list });
    }),

    // private:

    _searchValues: function _searchValues(term) {
      var _this = this;

      this.set('inputValue', term);
      if (term.includes(this.INPUT_PARSING_CHARACTER)) return;
      var filters = this.get('filters'),
          promise = this.store.queryRecord('segment-value', {
        filter: {
          segment_id: this.get('segment.id'),
          contains: term,
          start_date: filters.get('startDate'),
          end_date: filters.get('endDate')
        }
      });

      this.set('promise', promise);
      promise.then(function (values) {
        return _this.set('allValues', values.get('values').toArray());
      });
    },

    _selectSegmentValue: function _selectSegmentValue(value) {
      var resetValues = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var value_copy = _extends({}, this.get('filter.value'));

      if (!value_copy.values || resetValues) value_copy.values = [];
      if (!value_copy.operator) value_copy.operator = this.get('selectedOperator.value');
      value_copy.values.addObject(value);
      this.set('filter.value', value_copy);
    },

    _selectFilterOperator: function _selectFilterOperator(operator) {
      var value_copy = _extends({}, this.get('filter.value'));
      value_copy.operator = operator.value;
      this.set('filter.value', value_copy);
    },

    _searchLikeSegmentValue: function _searchLikeSegmentValue(value) {
      this._selectSegmentValue(value, true);
    },

    _enterKeyClick: function _enterKeyClick(inputValues) {
      var _this2 = this;

      this.set('invalidFilterValues', []);

      var valuesArray = inputValues.split(this.INPUT_PARSING_CHARACTER).map(function (value) {
        return value.trim();
      }),
          filters = this.get('filters'),
          invalidValues = [];

      if (!inputValues.length) return;
      var promise = this.store.queryRecord('segment-value', {
        filter: {
          segment_id: this.get('segment.id'),
          contains_list: inputValues,
          start_date: filters.get('startDate'),
          end_date: filters.get('endDate')
        }
      });

      this.set('promise', promise);
      promise.then(function (segmentValues) {
        var res = segmentValues.get('values').toArray();
        valuesArray.forEach(function (inputValue) {
          if (res.includes(inputValue)) {
            _this2._selectSegmentValue(inputValue);
          } else {
            invalidValues.push(inputValue);
            _this2.set('invalidFilterValues', invalidValues.uniq());
            _this2.notifyPropertyChange('invalidFilterValues');
          }
        });
      });
      this._searchValues('');
    },

    actions: {
      /**
       * Search segments in the list from its name
       * @param {String} term Segment name to search
       */
      searchValues: function searchValues(term) {
        run.debounce(this, this._searchValues, term, 600);
      },

      /**
       * Delete the segment fitler from dashboard filters
       */
      deleteFilter: function deleteFilter() {
        this.get('deleteFilter')(this.get('filter'));
      },

      /**
       * Add the selected segment to the filter list
       * @param {Model<Segment>} segment Segment selected from dropdown
       */
      onSelectSegmentFilter: function onSelectSegmentFilter(value) {
        if (value === this.get('i18n').t('sidebar.filters.segments.parsing_dropdown_default_option').string) {
          this.send('onEnterKey', this.get('inputValue'));
          return;
        }
        this._selectSegmentValue(value);
      },

      handleInputKeyUp: function handleInputKeyUp(value) {
        run.debounce(this, this._searchLikeSegmentValue, value, 500);
      },

      /**
       * Delete a segment from the filters
       * @param {Model<Segment>} segment Segment to delete
       */
      onDeleteSegmentsFilter: function onDeleteSegmentsFilter(value) {
        var value_copy = _extends({}, this.get('filter.value'));

        value_copy.values.removeObject(value);
        this.set('filter.value', value_copy);
      },

      /**
       * Build filters from input
       * @param {String} inputValues List of segments separatred by ;
       */
      onEnterKey: function onEnterKey(inputValues) {
        if (!inputValues.length) return;
        this._enterKeyClick(inputValues);
      },

      onSelectFilterOperator: function onSelectFilterOperator(filterOption) {
        this._selectFilterOperator(filterOption);
      }
    }
  });
});