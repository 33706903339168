define('due-dashboard/helpers/thousand-separator', ['exports', 'ember'], function (exports, _ember) {
  var htmlSafe = _ember['default'].String.htmlSafe;

  var SEPARATORS = {
    'fr': '&nbsp',
    'es': '.',
    'nl': '.'
  };

  exports['default'] = _ember['default'].Helper.helper(function (number, lang) {
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, SEPARATORS[lang.toLowerCase()] || ',');
    return htmlSafe(number);
  });
});