define('due-dashboard/components/loading-skeleton/due-vertical-text-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-text-skeleton}} Simple skeleton component for text and titles
   *
   * @module
   * @argument {string}  [size] - size of component
   * @argument {string}  [width] - width of the component
   * @argument {string}  [height] - height of the component
   * @argument {boolean} [fitParentWidth] - boolean to auto compute width based on parent
   * @argument {string}  [style] - style to customise the component
   * @argument {string}  [color] - custom color
   * @argument {string}  [customClass] - classes to customise the component
   * @example <caption>Full usage example of {{due-text-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-text-skeleton
   *   customClass='my-custom-class'
   *   size='medium'
   *   width=100
   *   height=15
   *   fitParentWidth=true
   *   color='my-custom-color'
   *   style='my-custom-style'
   * }}
   * ```
   */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-vertical-text-skeleton'],
    classNameBindings: ['customClass', 'size'],
    attributeBindings: ['style'],
    rendered: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('animationUID', 'linear-gradient-' + guidFor(this));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('rendered', true);
      this.set('xOffset', this.element.querySelector('svg').getBoundingClientRect().x);
      this.set('windowWidth', $('body > .ember-view').width());
      this.set('parentWidth', this.element.parentNode.getBoundingClientRect().width);
    },

    color: '#F6F8FB',
    heightFromSize: { small: 115, medium: 200, large: 343 },
    widthFromSize: { small: 10, medium: 12, large: 20 },

    computedAnimationUID: computed('emptyState', function () {
      return this.get('emptyState') ? '#F6F8FB' : 'url(#' + this.get('animationUID') + ')';
    }),

    recWidth: computed('width', 'size', 'parentWidth', function () {
      var computedWidth = this.get('fitParentWidth') ? this.get('parentWidth') : this.get('width');
      return Math.min(computedWidth || this.get('widthFromSize')[this.get('size')] || 200, this.get('parentWidth') || 200);
    }),

    recHeight: computed('height', 'size', function () {
      return this.get('height') || this.get('heightFromSize')[this.get('size')] || 12;
    }),

    recBorderRadius: computed('recWidth', function () {
      return this.get('recWidth') / 2;
    })
  });
});