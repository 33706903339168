define('due-dashboard/components/reports/charts/-combo-bar-line', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/helpers/current-timezone', 'moment'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardHelpersCurrentTimezone, _moment) {
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    classNames: ['report-chart-component', 'combo-bar-line-chart'],
    globalFilters: service('feedback-search/global'),

    content_data: [],
    isEmpty: false,
    isLoading: true,
    selectedSegment: undefined,
    segmentsList: undefined,

    selectedTimeSelector: { key: 'week', label: 'words.week' },
    TIME_SELECTOR: [{ key: 'day', label: 'words.day' }, { key: 'week', label: 'words.week' }, { key: 'month', label: 'words.month' }, { key: 'year', label: 'words.year' }],

    comboBarLineConfigOverride: computed('config', 'globalViewBy', function () {
      var config = this.get('config');

      if (config.time_period_selector) {
        config.timePeriod = this.get('globalViewBy.key');
        // config.primaryYAxis.fields.label = selectedSegment.name;
      }

      return config;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._queryData();
    },

    reloadObserver: observer('reload', 'globalViewBy', function () {
      this._queryData();
    }),

    _queryData: function _queryData() {
      var _this = this;

      var config = this.get('config');
      var enableSegmentSelection = this.get('enableSegmentSelection');
      this.set('isLoading', true);

      var global_filters = this.get('globalFilters.current'),
          report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
          component_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('content.component.filters')),
          graphFilters = global_filters.merge(report_filters, component_filters).toDataQueryFormat(),
          graphQuery = this.get('store').createRecord('chart'),
          dateRange = {
        from: '',
        to: ''
      };

      // Set date_range if present in filters
      if (graphFilters.start_date && graphFilters.end_date) {
        dateRange.from = (0, _moment['default'])(graphFilters.start_date[0][0]).format().split('T')[0];
        dateRange.to = (0, _moment['default'])(graphFilters.end_date[0][0]).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = (0, _moment['default'])(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = (0, _moment['default'])(date).format().split('T')[0];
      }

      // Remove date range from filters
      graphQuery.set('date_range', dateRange);
      delete graphFilters.start_date;
      delete graphFilters.end_date;

      var graphGroups = this.get('content.component.groups') || [];

      if (config.time_period_selector) {
        var timeSelector = this.get('globalViewBy');
        graphGroups.find(function (group) {
          return group._type == 'time';
        }).type = timeSelector.key;
      }

      var segmentGroup = graphGroups.find(function (group) {
        return group.type == 'segment';
      });
      if (segmentGroup) {
        segmentGroup.id = this.get('globalViewBy.key');
      }
      graphQuery.set('groups', graphGroups);
      graphQuery.set('filters', graphFilters);
      graphQuery.set('kpis', this.get('content.component.properties'));
      graphQuery.set('tz', _dueDashboardHelpersCurrentTimezone['default'].compute());

      graphQuery.save().then(function (response) {
        _this.set('content_data', response.get('content_data'));
        _this.set('isLoading', false);
        _this.set('isEmpty', response.get('content_data').length === 0);
      })['catch'](function (error) {
        _this.set('isLoading', false);
        _this.set('isEmpty', true);
      });
    }
  });
});