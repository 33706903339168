define('due-dashboard/components/due-distribution-breakdown-tooltip', ['exports', 'ember', 'due-dashboard/helpers/thousand-separator'], function (exports, _ember, _dueDashboardHelpersThousandSeparator) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      var sections = this.get('sections');
      var content = '\n      <div style="min-width: 280px;overflow: hidden; font-size: 12px; padding: 2px;">\n        <div style="display: flex; flex-direction: row; width: 100%; margin-bottom: 10px;">\n          <div style="width: 75%">\n            <span style="font-size: 12px; font-weight: 600;">' + this.get('title') + '</span>\n          </div>\n          <div style="width: 25%; display: flex; justify-content: flex-end;">\n            <span style="font-size: 12px; font-weight: 600;">' + _dueDashboardHelpersThousandSeparator['default'].compute(this.get('total'), this.get('currentLanguage')) + '</span>\n          </div>\n        </div>\n    ';
      sections.forEach(function (section) {
        content += '\n        <div style="display: flex; flex-direction: row; align-items: center;">\n          <div style="width: 5%;">\n            <div style="width: 10px; height: 10px; border-radius: 2px; background-color: ' + section.color + '; margin-right: 5px;"></div>\n          </div>\n          <div style="width: 80%; padding-left: 5px; display: flex; flex-direction: row; justify-content: flex-start;">\n            <p style="font-size: 12px; color: white;">' + section.title + '</p>\n          </div>\n          <div style="width: 20%; display: flex; flex-direction: row; justify-content: flex-end; padding-left: 10px;">\n            <p style="font-size: 12px; color: white;">' + section.percentage + '%</p>\n          </div>\n          <div style="width: 25%; display: flex; flex-direction: row; justify-content: flex-end; padding-left: 10px;">\n            <p style="font-size: 12px; color: white; font-weight: 600;">' + _dueDashboardHelpersThousandSeparator['default'].compute(section.total, _this.get('currentLanguage')) + '</p>\n          </div>\n        </div>\n      ';
      });
      content += '</div>';

      $(this.element).prev('div').tooltipster({
        theme: ['tooltipster-noir', 'tooltipster-noir-customized', 'tooltipster-noir-customized-centered'],
        content: content,
        contentAsHTML: true,
        animation: 'fade',
        delay: 1,
        speed: 100,
        hideOnClick: false,
        trigger: 'hover',
        autoClose: true,
        position: 'top',
        functionReady: function functionReady() {
          $('.due-distribution-breakdown').on("click", function () {
            $(".tooltipster-noir-customized-centered").tooltipster('show');
          });
          $('.due-distribution-breakdown').on("click", function () {
            $(".tooltipster-noir-customized-centered").tooltipster('show');
          });
        },
        arrow: false
      });
    }
  });
});
/* global $ */