define('due-dashboard/components/-react', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    tagName: 'div',

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      run.schedule('afterRender', function () {
        _this.loadReactComponent();
      });
    },

    loadReactComponent: function loadReactComponent() {
      var _this2 = this;

      var componentName = this.componentName;
      var componentProps = this.componentProps;

      var script = document.createElement('script');

      script.src = '/react/assets/index.js';
      script.onload = function () {
        if (window.ReactApp && window.ReactApp['mount' + componentName]) {
          window.ReactApp['mount' + componentName](_this2.element, componentProps);
        } else {
          console.error('ReactApp or mount' + componentName + ' is not defined');
        }
      };
      document.body.appendChild(script);
    }
  });
});