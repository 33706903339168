define('due-dashboard/components/reports/charts/-scatter-plot', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/helpers/current-timezone', 'moment'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardHelpersCurrentTimezone, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    classNames: ['report-chart-component', 'scatter-plot-chart'],
    globalFilters: service('feedback-search/global'),

    content_data: [],
    isEmpty: false,
    isLoading: true,

    init: function init() {
      this._super.apply(this, arguments);
      this._queryData();
    },

    scatterPlotConfigOverride: computed('config', 'globalViewBy', function () {
      var config = this.get('config');
      var selectedSegment = this.get('globalViewBy');

      config.primaryYAxis.fields[0].label = selectedSegment.label;

      return config;
    }),

    _queryData: function _queryData() {
      var _this = this;

      var global_filters = this.get('globalFilters.current'),
          report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('reportFilters')),
          component_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, this.get('content.component.filters')),
          graphFilters = global_filters.merge(report_filters, component_filters).toDataQueryFormat(),
          graphQuery = this.get('store').createRecord('chart'),
          dateRange = {
        from: '',
        to: ''
      };

      // Set date_range if present in filters
      if (graphFilters.start_date && graphFilters.end_date) {
        dateRange.from = (0, _moment['default'])(graphFilters.start_date[0][0]).format().split('T')[0];
        dateRange.to = (0, _moment['default'])(graphFilters.end_date[0][0]).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = (0, _moment['default'])(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = (0, _moment['default'])(date).format().split('T')[0];
      }

      // Remove date range from filters
      graphQuery.set('date_range', dateRange);
      delete graphFilters.start_date;
      delete graphFilters.end_date;

      var graphGroups = this.get('content.component.groups') || [];

      var segmentGroup = graphGroups.find(function (group) {
        return group.type == 'segment';
      });
      if (segmentGroup) {
        segmentGroup.id = this.get('globalViewBy.key');
      }

      graphQuery.set('groups', graphGroups);
      graphQuery.set('filters', graphFilters);
      graphQuery.set('kpis', this.get('content.component.properties'));
      graphQuery.set('tz', _dueDashboardHelpersCurrentTimezone['default'].compute());

      graphQuery.save().then(function (response) {
        _this.set('content_data', response.get('content_data'));
        _this.set('isLoading', false);
        _this.set('isEmpty', response.get('content_data').length === 0);
      })['catch'](function (error) {
        _this.set('isLoading', false);
        _this.set('isEmpty', true);
      });
    },

    reloadObserver: observer('reload', 'globalViewBy', function () {
      this._queryData();
    })
  });
});