define('due-dashboard/components/reports/-chart', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    classNames: ['report-chart'],
    classNameBindings: ['layout'],
    currentAccount: service(),
    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    mapping: {
      comboBarLine: 'ComboBarLine',
      scatter: 'Scatter',
      pie: 'Pie'
    },

    serializedChartConfiguration: computed('content', function () {
      var chartConfig = this.get('content');
      var currentLanguage = this.get('currentLanguage');
      var translations = chartConfig.component.config.translations[currentLanguage];

      var config = {
        kpis: chartConfig.component.properties,
        groups: chartConfig.component.groups,
        type: this.mapping[chartConfig.component.view_type],
        title: translations.title,
        view_by: chartConfig.component.config.view_by,
        time_period_selector: chartConfig.component.config.time_period_selector,
        xAxis: _extends({}, chartConfig.component.config.xAxis, {
          label: translations.xAxis.label
        }),
        primaryYAxis: _extends({}, chartConfig.component.config.primaryYAxis, {
          label: translations.primaryYAxis.label,
          fields: chartConfig.component.config.primaryYAxis.fields.map(function (field, index) {
            return _extends({}, field, {
              label: translations.primaryYAxis.fields[index].label
            });
          })
        })
      };

      if (chartConfig.component.config.secondaryYAxis) {
        config.secondaryYAxis = _extends({}, chartConfig.component.config.secondaryYAxis, {
          label: translations.secondaryYAxis.label,
          fields: chartConfig.component.config.secondaryYAxis.fields.map(function (field, index) {
            return _extends({}, field, {
              label: translations.secondaryYAxis.fields[index].label
            });
          })
        });
      }

      return config;
    })
  });
});