define('due-dashboard/serializers/chart', ['exports', 'due-dashboard/serializers/application'], function (exports, _dueDashboardSerializersApplication) {
  exports['default'] = _dueDashboardSerializersApplication['default'].extend({

    keyForAttribute: function keyForAttribute(key) {
      return key;
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      return json;
    }
  });
});