define('due-dashboard/objects/feedback-search/filter-set', ['exports', 'ember', 'due-dashboard/mixins/model-validation-methods', 'ember-cp-validations', 'due-dashboard/helpers/deep-freeze'], function (exports, _ember, _dueDashboardMixinsModelValidationMethods, _emberCpValidations, _dueDashboardHelpersDeepFreeze) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global _ */

  var Mixin = _ember['default'].Mixin;
  var alias = _ember['default'].computed.alias;
  var service = _ember['default'].inject.service;

  var TRANSIENT_FILTER_TYPES = (0, _dueDashboardHelpersDeepFreeze['default'])(['completed_at', 'locale', 'search_keyword', 'compare_to_period']),
      PERSISTENT_FILTER_TYPES = (0, _dueDashboardHelpersDeepFreeze['default'])(['comment', 'completed', 'deleted', 'languages', 'profiles', 'status', 'segments', 'sentiments', 'surveys', 'tags']),
      ALL_FILTER_TYPES = (0, _dueDashboardHelpersDeepFreeze['default'])([].concat(_toConsumableArray(TRANSIENT_FILTER_TYPES), _toConsumableArray(PERSISTENT_FILTER_TYPES))),
      FILTER_DEFAULTS = (0, _dueDashboardHelpersDeepFreeze['default'])({
    comment: { value: 'both' },
    completed: { value: 'all' },
    deleted: { value: 'not_deleted' },
    languages: { value: [] },
    profiles: { value: [] },
    sentiments: { value: [] },
    segments: { value: { id: null, operator: 'eq', values: [] } },
    status: { value: 'both' },
    surveys: { value: [] },
    tags: { value: { included: 'both', tags_sentiments: {} } }
  });

  function default_filter(type) {
    var filter = FILTER_DEFAULTS[type];

    if (!filter) {
      throw new Error('Invalid filter type: ' + type);
    }
    return _extends({ filter_type: type }, _.cloneDeep(filter));
  }

  var Validations = (0, _emberCpValidations.buildValidations)({
    'comment': (0, _emberCpValidations.validator)('inclusion', { 'in': ['both', 'with', 'without'], allowBlank: true }),
    'completed': (0, _emberCpValidations.validator)('inclusion', { 'in': ['all', 'true'], allowBlank: true }),
    'completed_at.start': (0, _emberCpValidations.validator)('date', { allowBlank: true }),
    'completed_at.end': (0, _emberCpValidations.validator)('date', { allowBlank: true })
  });

  var propsMixin = Mixin.create(_.zipObject(ALL_FILTER_TYPES, _.times(ALL_FILTER_TYPES.length, function () {
    return null;
  })));

  var FilterSet = _ember['default'].Object.extend(propsMixin, Validations, _dueDashboardMixinsModelValidationMethods['default'], {

    copy: function copy() {
      var copy = this.container.lookup('object:feedback-search/filter-set'),
          props_copy = _.cloneDeep(this.getProperties(ALL_FILTER_TYPES));

      copy.setProperties(props_copy);
      return copy;
    },

    merge: function merge() {
      var _ref;

      for (var _len = arguments.length, others = Array(_len), _key = 0; _key < _len; _key++) {
        others[_key] = arguments[_key];
      }

      var result = this.toJSON(),
          sources = others.map(function (o) {
        return o.toJSON();
      });
      var merged_props = undefined;

      var sourcesSurvey = [];
      for (var i = 0; i < sources.length; i++) {
        if (sources[i].surveys) {
          sourcesSurvey.push.apply(sourcesSurvey, _toConsumableArray(sources[i].surveys));
        }
      }
      if (result.surveys && result.surveys.length == 0) {
        if (sourcesSurvey.length > 0) {
          var _result$surveys;

          (_result$surveys = result.surveys).push.apply(_result$surveys, sourcesSurvey);
        } else {
          delete result.surveys;
        }
      }

      merged_props = (_ref = _).mergeWith.apply(_ref, [result].concat(_toConsumableArray(sources), [function (left_value, right_value, key) {
        if (!left_value || !right_value) {
          return;
        }

        if (key === 'surveys') {
          return _.intersection(left_value, right_value);
        } else if (key === 'segments') {
          return _.mergeWith(left_value, right_value, function (a, b) {
            var left = a || [],
                right = b || [];
            if (left.length == 0) {
              return right;
            }
            return _.intersection(left, right);
          });
        } else if (key === 'tags') {
          left_value.included = right_value.included || left_value.included;
          _.merge(left_value.tags_sentiments, right_value.tags_sentiments);
          return left_value;
        } else if (Array.isArray(left_value)) {
          return left_value.concat(right_value).uniq();
        }
      }]));
      return this.constructor.createWith(this.container, merged_props);
    },

    persistentFilters: function persistentFilters() {
      var _ref2;

      return (_ref2 = _).pick.apply(_ref2, [this.toJSON()].concat(_toConsumableArray(PERSISTENT_FILTER_TYPES)));
    },

    toJSON: function toJSON() {
      return _(this.getProperties(ALL_FILTER_TYPES)).omitBy(_.isNil).cloneDeep();
    },

    toAPIFormat: function toAPIFormat() {
      var _this = this;

      var dataQueryFormat = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var json = this.toJSON(),
          result = _(json).pick('comment', 'completed', 'deleted', 'search_keyword', 'locale').omitBy(_.isNil).value();

      if (json.completed_at) {
        result.start_date = json.completed_at.start;
        result.end_date = json.completed_at.end;
      }
      if (!_.isEmpty(json.profiles)) {
        result.client_profile = json.profiles.join(',');
      }
      if (json.status) {
        result.resolved = json.status;
      }
      if (!_.isEmpty(json.languages)) {
        result.languages = dataQueryFormat ? json.languages : json.languages.join(',');
      }
      if (json.tags) {
        result.tagged = json.tags.included;
        if (!_.isEmpty(json.tags.tags_sentiments)) {
          var tags_sentiments = _(json.tags.tags_sentiments).mapValues(function (sentiments) {
            return _.isEmpty(sentiments.filter(function (a) {
              return a !== undefined;
            })) ? ['allfeedback'] : sentiments;
          }).flatMap(function (sentiments, tag_id) {
            return sentiments.map(function (s) {
              return tag_id + '_' + s;
            });
          });
          result.tags_sentiments = dataQueryFormat ? tags_sentiments : tags_sentiments.join(',');
        }
      }
      if (!_.isEmpty(json.sentiments)) {
        result.sentiments = json.sentiments.join(',');
      }
      if (json.surveys) {
        result.surveys = dataQueryFormat ? json.surveys : json.surveys.join(',');
      }
      if (json.segments) {
        var segment_tuples = Object.entries(json.segments).filter(function (_ref3) {
          var _ref32 = _slicedToArray(_ref3, 2);

          var _ = _ref32[0];
          var content = _ref32[1];
          return content.values && content.values.length > 0;
        }).map(function (_ref4) {
          var _ref42 = _slicedToArray(_ref4, 2);

          var id = _ref42[0];
          var content = _ref42[1];

          var formatted_values = _this._formatSegmentValues(content.values);
          return id + ':' + content.operator + ':' + formatted_values;
        });
        if (segment_tuples.length > 0) {
          result.segments = dataQueryFormat ? segment_tuples : segment_tuples.join(',');
        }
      }
      return result;
    },

    toDataQueryFormat: function toDataQueryFormat() {
      var source = this.toAPIFormat(true);

      // Transform to the weird data-query format
      return _(source).mapValues(function (value) {
        return [Array.isArray(value) ? value : [value]];
      }).value();
    },

    isEqual: function isEqual(other) {
      return _.isEqual(this.toJSON(), other.toJSON());
    },

    // private:

    init: function init() {
      this._super.apply(this, arguments);
      this.set('locale', this.get('_i18n.locale').toUpperCase());
      if (this.get('canAccessStandardReport') || this.get('displayPeriodComparison')) {
        this.set('compare_to_period', 'previous_period');
      }
    },

    _i18n: service('i18n'),
    currentAccount: service(),
    canAccessStandardReport: alias('currentAccount.canAccessStandardReport'),
    displayPeriodComparison: alias('currentAccount.displayPeriodComparison'),

    /**
     * Format segment values
     * Avoids that segment with value like "ABC,DEF" is sent as ABC and DEF separately
     * we replace commas in segment values with a standard fullwidth comma (U+FF0C) for displaying
     */
    _formatSegmentValues: function _formatSegmentValues(values) {
      return values.map(function (v) {
        return v.replace(/&/g, 'ꝸ');
      }).map(function (v) {
        return v.replace(/,/g, '，');
      }).join(',');
    }
  });

  FilterSet.reopenClass({

    TRANSIENT_FILTER_TYPES: TRANSIENT_FILTER_TYPES,
    PERSISTENT_FILTER_TYPES: PERSISTENT_FILTER_TYPES,
    ALL_FILTER_TYPES: ALL_FILTER_TYPES,

    defaultFilter: function defaultFilter(type) {
      return default_filter(type);
    },

    createWith: function createWith(container) {
      var props = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var created = container.lookup('object:feedback-search/filter-set');

      created.setProperties(props);
      return created.validateStrictSync();
    },

    createWithLegacy: function createWithLegacy(container, legacy_filters) {
      return this.createWith(container, this._convertLegacyFilters(legacy_filters));
    },

    // private:

    _convertLegacyFilters: function _convertLegacyFilters(_hash) {
      var hash = _extends({}, _hash),
          convert_array = function convert_array(hash, from, _to) {
        var to = _to || from,
            input = hash[from];

        delete hash[from];
        if (_.isEmpty(input)) {
          return;
        }
        hash[to] = _.isArray(input) ? input : input.split(',');
      };

      hash.status = hash.resolved;
      delete hash.resolved;
      convert_array(hash, 'surveys');
      convert_array(hash, 'languages');
      convert_array(hash, 'client_profile', 'profile');
      convert_array(hash, 'sentiments');
      if (hash.tags || hash.tags_sentiments) {
        var tags = hash.tags,
            tagged = hash.tagged || 'tagged';
        var ts = undefined;

        if (hash.tags_sentiments) {
          ts = _(hash.tags_sentiments).map(function (s) {
            return s.split('_');
          }).groupBy(_.first).mapValues(_.second).value();
        } else if (hash.tags) {
          ts = _(hash.tags).map(function (id) {
            return [id, ['allfeedback']];
          }).fromPairs().value();
        }
        delete hash.tags;
        delete hash.tagged;
        delete hash.tags_sentiments;
        if (ts) {
          hash.tags = { included: tagged, tags_sentiments: ts };
        }
      }
      if (hash.segments) {
        hash.segments = hash.segments.reduce(function (acc, raw_segment) {
          var _raw_segment$split = raw_segment.split(/:(.*)/);

          var _raw_segment$split2 = _slicedToArray(_raw_segment$split, 3);

          var id = _raw_segment$split2[0];
          var operator = _raw_segment$split2[1];
          var merged_values = _raw_segment$split2[2];
          var values = merged_values.split(',');

          acc[id] = { operator: operator, values: values };
          return acc;
        }, {});
      }
      return hash;
    }

  });

  exports['default'] = FilterSet;
});