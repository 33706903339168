define('due-dashboard/components/reports/array/-selected-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-selected-item'],

    text: computed('value', function () {
      var textKey = this.get('textKey'),
          text = this.get('i18n').t(textKey, { value: this.get('value.' + (this.get('displayAttribute') || 'text')) || this.get('value') });

      return text;
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/array/-selected-item}} selected pagination component used by {{reports/-array}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */